.large-pill-btn {
    background-color: #df3;
    width: 100%;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
}

.rounded-icon-btn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #df3;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.unstyled-btn {
    border: none;
    background-color: transparent;
}