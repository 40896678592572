.popover-no-padding {
    &.popover {
        background-color: #133b4b;

        .list-group-item {
            background: none;
            color: white;
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
    }

    .popover-body {
        padding: 0;

        .list-group {
            background-color: rgba(0, 0, 0, 0.195);
        }
    }

    .popover-arrow::before, .popover-arrow::after {
        border-left-color: transparent !important;
    }
}