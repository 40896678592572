@use './variables' as *;
@use './mixins' as *;


// Text color
.text-firsty-green {
    color: $firstyGreen !important;
}

// Bg color
.bg-dark-blue-transparent {
    background-color: rgba(0, 0, 0, 0.195);
}

.bg-dark-blue-transparent-0-3 {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-firsty-green {
    background-color: $firstyGreen !important;
}




.br-0-5rem {
    border-radius: 0.5rem;
}

.paid-invoice {
    @include invoice-status(rgba($color: $green, $alpha: 1.0));
}

.unpaid-invoice {
    @include invoice-status($red);
}

.z-0 {
    z-index: 0;
}

.blur-backdrop {
    backdrop-filter: blur(10px);
}

.form-input-error {
    // background: rgb(255, 255, 255, 0.5);
    padding: 2px 5px;
    border-radius: 16px;
    color: #c10000;
    // font-weight: 400;
    font-weight: bold;
}

.break-spaces {
    white-space: break-spaces;
}

.mb-200px {
    margin-bottom: 200px;
}