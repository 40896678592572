@use "../app/shared/sass" as *;
/* @import "overrides"; // Import overrides before Bootstrap itself */
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Thasadith:wght@400;700&display=swap");

$light: rgb(248, 249, 250);
$dark: rgb(24, 15, 24);
$primary: rgb(221, 255, 51);
$blue: rgb(123, 216, 224);
$green: rgb(115, 191, 130);
$yellow: rgb(255, 214, 112);
$pink: rgb(233, 142, 177);
$orange: rgb(255, 151, 112);
$red: rgb(189, 80, 89);
$quaternary: rgb(111, 141, 212);
$quinary: rgb(125, 221, 192);
$seconday: rgb(255, 192, 203); // pink
$tertiary: rgb(237, 229, 174);

// body {
//     font-family: Inter Tight, sans-serif;
// }

// h1 {
//     font-size: 26px !important;
// }

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
    font-family: "Space Grotesk", sans-serif;
    font-weight: 300; /* Available weights: 300, 400, 500, 600, 700 */
    margin: 0;
    padding: 0;
    height: 100vh;
    //background: radial-gradient(circle, #3e738b 0%, #0a2f3d 100%);
    background: linear-gradient(0deg, #0a2f3d, #3e738b, #0a2f3d, #3e738b);
    background-size: 400% 400%;
    animation: gradient 50s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


h1 {
    font-family: "Thasadith", sans-serif;
    font-weight: 700; /* Available weights: 400, 700 */
    font-size: 26px !important;
    color: $light;
}

h2 {
    font-family: "Thasadith", sans-serif;
    font-weight: 400; /* Available weights: 400, 700 */
    font-size: 18px !important;
    color: $light;
}

.progress-header {
    background-color: rgb(24, 15, 24, 0) !important;
}

.progress-bar-animated {
    background-color: rgb(24, 15, 24, 0) !important;
    height: 75%;
}

.progress-header,
.progress-stacked {
    --bs-progress-border-radius: 0;
}

.btn-dark {
    font-weight: 500 !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $dark !important;
    color: $dark !important;
}

.btn-primary {
    font-weight: 500 !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $green !important;
    color: $green !important;
}

.btn-secondary {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $pink !important;
    color: $pink !important;
}

.btn-tertiary {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $orange !important;
    color: $orange !important;
}

.btn-info {
    font-weight: 400 !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $blue !important;
    color: $blue !important;
}

.btn-warning {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $yellow !important;
    color: $yellow !important;
}

.btn-danger {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $red !important;
    color: $red !important;
}

.btn-quaternary {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $quaternary !important;
    color: $quaternary !important;
}

.btn-quinary {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $quinary !important;
    color: $quinary !important;
}

.modal .btn-primary {
    font-weight: 500 !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: $green !important;
    color: $green !important;
}

:root {
    --card-bg: #22262e;
    --card-header: #1f242d;
    --border-soft: rgba(255, 255, 255, 0.07);
    --text-primary: #ffffff;
    --text-muted: #dadada;
}

/* Cards */
.card {
    background: var(--card-bg);
    border: 1px solid var(--border-soft);
    border-radius: 3px;
    margin-bottom: 1rem;
}

.card-header {
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid var(--border-soft);
    padding: 0.875rem 1rem;
    font-size: 0.9375rem;
    font-weight: 500;
    color: var(--text-primary);
}

.card-body {
    padding: 0;
}

/* Info Rows */
.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid var(--border-soft);
    min-height: 3rem;
}

.info-row:last-child {
    border-bottom: none;
}

.info-label {
    color: var(--text-muted);
    font-size: 0.875rem;
}

.info-value {
    color: var(--text-primary);
    font-size: 0.875rem;
    text-align: right;
}

/* Forms */
.form-control,
.form-select {
    background: rgb(31, 36, 45, 0.2) !important;
    border: 1px solid var(--border-soft) !important;
    border-radius: 3px;
    color: var(--text-primary) !important;
    font-size: 0.875rem;
    padding: 0.625rem 0.875rem;
}

.form-control:focus,
.form-select:focus {
    border-color: #3a7bd5 !important;
    box-shadow: none !important;
    color: var(--text-primary) !important;
}

.form-floating .form-control,
.form-floating .form-control:focus,
.form-floating .form-control:active {
    color: var(--text-primary) !important;
    background: rgb(31, 36, 45, 0.2) !important;
}

.form-floating label {
    color: white !important;
}

.form-floating .form-control {
    background: rgb(31, 36, 45, 0.2) !important;
    color: white !important;
}

.form-floating .form-control:focus ~ label,
.form-floating .form-control:not(:placeholder-shown) ~ label {
    color: white !important;
    // background: rgb(31, 36, 45, 0.2) !important;
    background-color: transparent;
}

.form-floating > .form-control:focus ~ label::after {
    background-color: transparent;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
    background-color: transparent;
}
/* Nav Pills */
.nav-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-pills {
    margin-bottom: 1rem;
}

.nav-pills .nav-link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-size: 17px !important;
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--text-muted);
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 3px;
}

.nav-pills .nav-link.active {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
}

/* Search Button */
.btn-search {
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 3px;
    color: white;
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    font-weight: 500;
}

/* Labels */
.badge {
    padding: 0.25rem 0.5rem;
    border-radius: 2px;
    font-size: 0.75rem;
    font-weight: 500;
}

.badge-active {
    background: rgba(46, 204, 113, 0.15);
    color: #2ecc71;
}

.badge-disabled {
    background: rgba(231, 76, 60, 0.15);
    color: #e74c3c;
}
.ng-select .ng-select-dropdown {
    background-color: white; /* Background for the entire dropdown */
    border: 1px solid #ced4da; /* Add a border for better visibility */
}

.card {
    background-color: rgba(0, 0, 0, 0.2);
    border-style: none;
}

.card-element {
    color: $light;
}

.form-floating .ng-pristine .ng-valid .ng-touched {
    background-color: rgba(0, 0, 0, 0.2) !important;
    border: none !important;
    color: $light !important;
}

.accordion-item {
    background: transparent !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.accordion-button {
    background: transparent !important;
    color: white !important;
}

.accordion-button::after {
    filter: invert(1); /* Makes the dropdown arrow white */
}

.accordion-button:not(.collapsed) {
    background: transparent !important;
    color: white !important;
    box-shadow: none;
}

.accordion-body {
    background: transparent !important;
    color: white !important;
}

/* Table container */
.table {
    background-color: transparent !important;
}

/* Table header */
.table thead th {
    background-color: rgba(4, 7, 7, 0.552) !important;
    color: white !important;
    border: none !important;
    padding: 12px !important;
    font-weight: normal !important;
}

/* Table cells */
.table td {
    border: none !important;
    padding: 12px !important;
    color: white !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
}

/* Row styling */
.table tbody tr {
    margin-bottom: 2px !important; /* Creates separation between rows */
}

/* Remove any default borders */
.table,
.table * {
    border-collapse: separate !important;
    //border-spacing: 0 2px !important;
}

/* Remove all hover effects */
.table tbody tr:hover,
.table tbody tr:hover td,
.table tr:hover,
.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
    --bs-table-hover-bg: transparent !important;
    --bs-table-accent-bg: transparent !important;
}

/* You might also need to add this if you're using Bootstrap's table-hover class */
.table-hover > tbody > tr:hover {
    --bs-table-hover-bg: transparent !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
}

.progress {
    background-color: var(--border-soft);
    border-radius: 3px;
    overflow: hidden;
}

.progress-bar {
    transition: width 0.3s ease;
}

.bg-success {
    background-color: var(--success) !important;
}

.bg-danger {
    background-color: var(--danger) !important;
}

.col-md-6 {
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .card {
        margin-bottom: 1rem;
    }
}

/* Dark theme pagination styles */
.pagination-dark {
    .page-link {
        background-color: rgba(33, 37, 41, 0.3);
        border-color: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.75);
        padding: 0.375rem 0.75rem;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            border-color: rgba(255, 255, 255, 0.2);
            color: white;
        }
    }

    .page-item.active .page-link {
        background-color: #0d6efd;
        border-color: #0d6efd;
        color: white;
    }

    .page-item.disabled .page-link {
        background-color: rgba(33, 37, 41, 0.15);
        border-color: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.3);
    }
}

/* Select and ng-select styling */
.form-select,
.ng-select {
    background: rgba(31, 36, 45, 0.2) !important;
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    border-radius: 3px;
    color: #fff !important;
    font-size: 0.875rem;
    min-width: 200px;

    &:focus {
        border-color: #3a7bd5 !important;
        box-shadow: none !important;
    }
}

/* ng-select specific overrides */
::ng-deep .ng-select {
    .ng-select-container {
        background: rgba(31, 36, 45, 0.2) !important;
        border: none !important;
        color: #fff !important;
        min-height: 38px;
    }

    .ng-dropdown-panel {
        background: rgba(31, 36, 45, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.07);
        backdrop-filter: blur(10px);

        .ng-option {
            background: transparent;
            color: #fff;
            padding: 8px 12px;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            &.ng-option-selected {
                background: rgba(58, 123, 213, 0.2);
            }
        }
    }

    .ng-value-container .ng-value {
        background-color: rgba(58, 123, 213, 0.2);
        color: #fff;
        border-radius: 3px;
        padding: 2px 8px;
        margin: 2px;
    }

    .ng-placeholder {
        color: rgba(255, 255, 255, 0.5);
    }
}

/* Input group styling */
.input-group {
    .input-group-text {
        background: rgba(31, 36, 45, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.07);
        border-right: none;
        color: #dcdcdc;
        font-size: 0.875rem;
    }

    .form-control {
        background: rgba(31, 36, 45, 0.2) !important;
        border: 1px solid rgba(255, 255, 255, 0.07) !important;
        border-left: none !important;
        color: #fff !important;
        font-size: 0.875rem;

        &:focus {
            border-color: #3a7bd5 !important;
            box-shadow: none !important;
        }
    }
}

.ng-dropdown-panel {
    background: rgba(31, 36, 45, 0.8) !important;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;

    .ng-dropdown-panel-items {
        background: rgba(31, 36, 45, 0.8) !important;

        .ng-option {
            background: rgba(31, 36, 45, 0.8) !important;
            color: white; // For better text visibility on dark background

            &:hover {
                background: rgba(31, 36, 45, 0.95) !important;
            }

            &.ng-option-selected {
                background: rgba(31, 36, 45, 0.2) !important;
            }
        }
    }

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #1a1a1a;
    }

    &::-webkit-scrollbar-thumb {
        background: #444;
        border-radius: 4px;
    }
}

/* Modal Styles */
.modal-dialog {
    max-width: 60%;
    margin: 1.75rem auto;
}

@media (max-width: 768px) {
    .modal-dialog {
        max-width: 100%;
        margin: 0;
        min-height: 100vh;
    }

    .modal-content {
        min-height: 100vh;
        border: none;
        border-radius: 0;
    }
}

.modal-content {
    background: rgba(34, 38, 46, 0.45);
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    backdrop-filter: blur(10px);
    color: #fff;
}

.modal-header {
    background: rgba(31, 36, 45, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    padding: 1rem 1.5rem;

    .modal-title {
        color: #fff;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .btn-close {
        filter: invert(1) grayscale(100%) brightness(200%);
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }
}

.modal-body {
    padding: 1.5rem;
    background: transparent;
}

.modal-footer {
    background: rgba(31, 36, 45, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.07);
    padding: 1rem 1.5rem;
}

/* Modal Button Styles */
.modal .btn {
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 3px;
}

/* Form Controls inside Modal */
.modal .form-control,
.modal .form-select,
.modal .input-group-text {
    background: rgba(31, 36, 45, 0.85) !important;
    border: 1px solid rgba(255, 255, 255, 0.07) !important;
    color: #fff !important;
}

.toast {
    background: rgba(34, 38, 46, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 3px;
    backdrop-filter: blur(10px);
    color: #fff;

    .toast-header {
        background: rgba(31, 36, 45, 0.85);
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);
        color: #fff;

        .btn-close {
            filter: invert(1) grayscale(100%) brightness(200%);
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }
    }

    &.bg-danger {
        background: rgba(220, 53, 69, 0.15) !important;
        border-color: rgba(220, 53, 69, 0.2);
    }

    &.bg-success {
        background: rgba(40, 167, 69, 0.15) !important;
        border-color: rgba(40, 167, 69, 0.2);
    }
}

// .btn-group {
//     width: 100%;
// }

.btn-group .btn {
    min-width: 0;
    padding-left: 1rem;
    padding-right: 1rem;
}

.btn {
    border-radius: 3px;
}

.btn-group {
    .btn:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .btn:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

.form-check-input {
    background-color: rgba(31, 36, 45, 0.85);
    border-color: rgba(255, 255, 255, 0.2);
    width: 2.5em;
    height: 1.25em;
    margin-top: 0.25em;

    &:checked {
        background-color: #3a7bd5;
        border-color: #3a7bd5;
    }

    &:focus {
        border-color: rgba(255, 255, 255, 0.3);
        box-shadow: 0 0 0 0.2rem rgba(58, 123, 213, 0.25);
    }
}

.form-switch {
    padding-left: 3em;
    margin-bottom: 1rem;

    .form-check-input {
        cursor: pointer;

        &:not(:checked):not(:focus) {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,0.4)'/%3e%3c/svg%3e");
        }
    }

    .form-check-label {
        color: #fff;
        font-size: 0.875rem;
        cursor: pointer;
    }
}

.modal-info-table {
    background: rgba(34, 38, 46, 0.85);
    color: #fff;

    th {
        color: #8b92a5;
        font-weight: 500;
        font-size: 0.875rem;
        border-color: rgba(255, 255, 255, 0.07);
        background: rgba(31, 36, 45, 0.85);
        width: 250px;
    }

    td {
        color: #fff;
        font-size: 0.875rem;
        border-color: rgba(255, 255, 255, 0.07);
    }

    tr:hover {
        background: rgba(255, 255, 255, 0.05);
    }
}

.btn-group {
    gap: 4px; /* Adds small gap between buttons */

    .btn {
        margin: 0 1px; /* Alternative way to add spacing */

        /* To prevent border radius on inner edges while maintaining it on outer edges */
        &:not(:first-child):not(:last-child) {
            border-radius: 3px;
        }

        &:first-child {
            border-radius: 3px;
        }

        &:last-child {
            border-radius: 3px;
        }
    }
}

.advanced-pie-legend{
    color: white;
}

::ng-deep .tick text {
    fill: white;
  }

ngb-pagination .pagination {
    margin: 0;
}