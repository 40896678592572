.message-modal {
    .modal-dialog {
        max-width: fit-content;
        min-width: 300px;
        margin: 0 auto;
        padding: 0 24px;;

        .modal-content {
            border-radius: 1rem;
            min-height: unset;
        }
    }
}