.sorting-order-indicator {
    display: inline-block;
    width: 20px;
}

.rounded-table {
    thead {
        tr:first-child th:first-child {
            border-top-left-radius: 0.5rem;
        }
        tr:first-child th:last-child {
            border-top-right-radius: 0.5rem;
        }
    }
    tbody {
        tr:last-child td:first-child {
            border-bottom-left-radius: 0.5rem;
        }
        tr:last-child td:last-child {
            border-bottom-right-radius: 0.5rem;
        }
    }
}
